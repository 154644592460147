import logo from './logo.svg';
import './App.css';
import ForceGraph3D from 'react-force-graph-3d';
import {useEffect, useState} from "react";
import SpriteText from 'three-spritetext';


function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}


function rainbowColor(f){
	if(f<0 || f>1){
		//fmod

		f=f-Math.floor(f)
	}

	let phase=Math.floor(f*6)

	let f2=f*6-phase

	let t=1-f2

	let r=0
	let g=0
	let b=0

	switch(phase){
		case 0:
			r=1
			g=f2
			b=0
			break;
		case 1:
			r=t
			g=1
			b=0
		break;
		case 2:
			r=0
			g=1
			b=f2
		break;
		case 3:
			r=0
			g=t
			b=1
		break;
		case 4:
			r=f2
			g=0
			b=1
		break;
		case 5:
			r=1
			g=0
			b=t
		break;

	 }

	let ret="#"+componentToHex(Math.floor(r*255))+componentToHex(Math.floor(g*255))+componentToHex(Math.floor(b*255))
	return ret

}
function blueToRed(f){
	if(f<0 || f>1){
		//fmod

		f=f-Math.floor(f)
	}

	let b=f
	let r=1-f

	let ret="#"+componentToHex(Math.floor(r*255))+"00"+componentToHex(Math.floor(b*255))
	return ret
	
}

function rgbToHex(r,g,b){
	return "#"+componentToHex(r)+componentToHex(g)+componentToHex(b)
}
function FullScreenCenteredLoading(){
	return <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
		<h1 style={{color:"#ffffff"}}>
		Loading...</h1>


	</div>
}

function App() {
	let [myData, setMyData] = useState(null)

	const currentProtocol = window.location.protocol; // Get the current protocol (http or https)
	const currentDomain = window.location.hostname; // Get the current domain
	const port = 3006; // Set the desired port number (in this case, 3000)

	const baseURL = `${currentProtocol}//${currentDomain}`; // Combine protocol, domain, and port

	useEffect(() => {
		fetch(baseURL+'/links.json').then((response) => {
			return response.json();
		}).then((data) => {
			setMyData(data);
		});
	}, []);


	return (
		<div className="App" style={{backgroundColor:"#000000"}}>

			{myData === null ? <FullScreenCenteredLoading/> :


			<ForceGraph3D
				nodeId={"id"}
				graphData={myData}
				enableNodeDrag={false}
				//nodeLabel={n=>n.depth+" "+n.link}
				//nodeAutoColorBy={(n)=>n.lvl===0?1:n.link==="https://google.com"?2:3}
				//nodeColor={(n)=>n.depth===1?"#ff0000":rainbowColor((n.depth/max)*0.9)}
				onNodeClick={(node) => {
					//open link
					window.open(node.link)
				}}
				onNodeHover={(node) => {
					console.log(node)
				}}
				nodeThreeObject={n=> {
					const sprite = new SpriteText(n.domain);

					//let color=n.lvl===0?1:n.link==="https://google.com"?2:3

					//color=color===1?"#ffff00":color===2?"#00ffff":"#ff00ff"


					if(n.lvl!==0){
						if(n.id==="1"){
							sprite.color="#00ff00"
						}else{
							sprite.color="#ffffff"
						}
					}else{
						sprite.color=blueToRed(n.bdist)
					}
					sprite.textHeight = 8;
					return sprite;
				}}

				forceEngine="ngraph"
				linkColor="#ffffff"
				linkOpacity={1}
				linkWidth={0}
			/>
			}
		</div>
	);
}

/*
				nodeThreeObject={n=> {
					const sprite = new SpriteText(n.domain);

					let color=n.lvl===0?1:n.link==="https://google.com"?2:3

					color=color===1?"#ffff00":color===2?"#00ffff":"#ff00ff"
					sprite.color=color
					sprite.textHeight = 8;
					return sprite;
				}}
				}}
				*/
export default App;
